<template>
  <div>
    <b-row>
      <b-col cols="12" lg="12">
        <!--table details invoice sellpoint-->
        <ek-table
          :items="sell"
          :columns="DetailSellCols"
          no_delete
          @details="goToDetailsInvoice"
        >
          <template slot="items.dateCreated" slot-scope="{ value }">
            {{ value ? new Date(value).toLocaleDateString() : "_" }}
          </template>
        </ek-table>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  props: {
    id: String,
  },
  computed: {
    ...mapState({
      sellpointsDetails: ({ invoiceSellPoint }) => invoiceSellPoint.sellpointsDetails,
      DetailSellCols: ({ invoiceSellPoint }) => invoiceSellPoint.DetailSellCols,
    }),
    ...mapGetters(["sell"]),
  },
  data: () => ({}),
  methods: {
    ...mapActions(["getDetailsSellPointList"]),
    goToDetailsInvoice({ row }) {
      this.$router.push({
        path: `/admin/invoiceSellPoint/${this.id}/${row.id}`,
      });
    },
  },
  created() {
    this.getDetailsSellPointList(this.id);
  },
};
</script>
